@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
*,
body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Inter';
}

.bg-background {
  background-color: #fff;
}

.bg-main {
  background-color: #003a63;
}

.bg-error {
  background-color: #e74c3c;
}

.bg-primary {
  background-color: #1bb55b;
}

.bg-secondary {
  background-color: #2a719f;
}

.bg-tertiary {
  background-color: #ffbb12;
}

.bg-gradient {
  background: linear-gradient(
    90.01deg,
    #003a63 0.01%,
    #0d4e7b 50%,
    #185c8b 99.99%
  );
}

.bg-icon {
  background-color: #979797;
}

.chip {
  color: #a8a9ad;
  border: 1px solid #a8a9ad;
  border-radius: 18.5px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  padding: 8px 12px;
}

.chip-clicked {
  background: #003a63;
  border: 1px solid #003a63;
  color: #fff;
}

.chip:hover {
  cursor: pointer;
}

.icon-chip {
  height: 30px;
  border: 1px solid #003a63;
  box-sizing: border-box;
  border-radius: 18.5px;
  font-size: 14px;
  line-height: 16px;
  color: #003a63;
  font-family: Inter;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 15px 12px;
}

.icon-chip:hover {
  cursor: pointer;
  background: #003a63;
  border: 1px solid #003a63;
  color: #fff;
}

.text-buy {
  color: #aa1c1a;
}

.text-sell {
  color: #00b82a;
}

.text-finance {
  color: #00ac83;
}

.text-tax {
  color: #1b6392;
}

.text-invest {
  color: #782ebf;
}

.text-more {
  color: #31bf00;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.loading {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
